<template>
  <div>
    <div class="row border-top mt-1 pt-1 mb-3">
      <div class="col-6">
        <select-input
          label="Specimen"
          :items="specimens"
          displayExpr="specimenOrder"
          name="specimenId"
          v-model="imageData.specimenId"
        />
        <select-input
          label="Code"
          :items="imageTypes"
          :validator="$v.imageData.imageTypeId"
          name="imageTypeId"
          v-model="imageData.imageTypeId"
        />
        <select-input
          label="File Type"
          :items="fileExtensionOptions"
          name="fileExtension"
          v-model="imageData.fileExtension"
        />
        <select-input
          name="printOnReport"
          label="Print On Report"
          id="printOnReport"
          :items="booleanOptions"
          v-model="imageData.printOnReport"
        />
        <tag-input
          label="Tags"
          :items="tagOptions"
          id="tagIds"
          name="tagIds"
          class="tags-input"
          v-model="imageData.tagIds"
        />
        <text-input
          label="Keywords"
          class="keywords-input"
          name="keywords"
          v-model="imageData.keywords"
        />
      </div>
      <div v-if="multiUpload && imageUrl" class="col-6 my-auto d-flex justify-content-center">
        <img :src="imageUrl" class="mx-auto mt-2" />
      </div>
      <div v-else class="col-6 my-auto">
        <div ref="imagePreview" class="w-100 mx-auto"></div>
        <div class="d-flex justify-content-center mt-1">
          <icon-button
            @click="handlePrevious"
            v-if="hasMultipleImages && imageIdx > 0"
            class="btn-outline-primary mx-2"
            icon="arrow-left"
          >
          </icon-button>

          <icon-button
            @click="handleNext"
            v-if="hasMultipleImages && imageIdx < imageCount - 1"
            class="btn-outline-primary"
            icon="arrow-right"
          >
          </icon-button>
        </div>
        <Loader v-show="isOperating" size="small" class="mx-auto mt-2" />
        <p v-show="hasMultipleImages && !multiUpload" class="mx-auto">
          Multiple images will be saved as a multi-page PDF.
        </p>
      </div>
    </div>
    <text-area-input
      class="comments-input"
      name="comment"
      label="Comments"
      v-model="imageData.comment"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SelectInput from "./common/SelectInput.vue";
import TagInput from "./common/TagInput.vue";
import TextInput from "./common/TextInput.vue";
import { mapState } from "vuex";
import { booleanLookup } from "@/modules/helpers";
import TextAreaInput from "./TextAreaInput.vue";
import IconButton from "./common/IconButton.vue";
import Loader from "./common/Loader.vue";
import Dynamsoft from "dwt";
import { WEB_TWAIN_ID } from "@/modules/constants";

export default {
  components: { SelectInput, TagInput, TextInput, TextAreaInput, IconButton, Loader },
  props: ["value", "multiUpload", "imageCount", "isOperating", "imageTypes", "tagOptions"],
  data() {
    return {
      fileExtensionOptions: [
        { id: 0, displayName: "png" },
        { id: 1, displayName: "pdf" },
        { id: 2, displayName: "tif" }
      ],
      booleanOptions: booleanLookup.dataSource,
      imageIdx: 0,
      imageUrl: "",
      hasMultipleImages: false
    };
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.specimens
    }),
    imageData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    this.startTwain();
  },
  watch: {
    "imageData.imageTypeId": {
      immediate: true,
      handler(nv) {
        if (nv) {
          const imageType = this.imageTypes.find(e => e.id === nv);
          if (imageType && imageType.fileTypeId) {
            this.imageData.fileExtension = parseInt(imageType.fileTypeId);
          }
        }
      }
    }
  },
  validations() {
    return {
      imageData: {
        imageTypeId: { required }
      }
    };
  },
  methods: {
    handlePrevious() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.previous();
    },
    handleNext() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.next();
    },
    async startTwain() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      if (DWObject) {
        if (this.multiUpload) {
          this.imageUrl = DWObject.GetImageURL(this.imageData.imageIndex, 500, 360);
        } else {
          const element = this.$refs.imagePreview;
          DWObject.Viewer.bind(element);
          DWObject.Viewer.width = "100%";
          DWObject.Viewer.height = 400;
          DWObject.Viewer.singlePageMode = true;
          DWObject.Viewer.show();
          // this.getSources();
          this.hasMultipleImages = DWObject.HowManyImagesInBuffer > 1;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
