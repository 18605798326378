import Vue from "vue";
import { fromEventPattern } from "rxjs";
const eventBus = new Vue();

// ** FILTERS
export const SIGNOUT_FILTER = [
  ["caseStatus", "=", 6],
  "or",
  ["caseStatus", "=", 12],
  "or",
  ["caseStatus", "=", 9]
];
export const ACCESSION_FILTER = [
  "!",
  ["caseStatus", "=", 8],
  "or",
  ["caseStatus", "=", 11],
  "or",
  ["caseStatus", "=", 14]
];

// ** EVENTS
export const CHANGE_GROUP = "CHANGE_GROUP";
export const CHANGE_MODE = "CHANGE_MODE";
export const RUN_ICD_ENGINE = "RUN_ICD_ENGINE";
export const FORCE_USER_FOCUS = "FORCE_USER_FOCUS";
export const CHECK_PREFIX = "CHECK_PREFIX";
export const OPEN_ORDERS = "OPEN_ORDERS";
export const CLOSE_ORDERS = "CLOSE_ORDERS";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const PRINT_LABELS = "PRINT-LABELS";
export const DISPATCH_NUMBER_OF_BLOCKS = "DISPATCH-BLOCK-NUM";
export const REQUEST_NUMBER_OF_BLOCKS = "REQUEST-BLOCK-NUM";
export const OPEN_MACRO_POPUP = "OPEN_MACRO_POPUP";
export const RESTORE_EDITOR_POSITION = "RESTORE-EDITOR-POSITION";
export const SIGNED_CASE_FROM_PDF = "SIGNED_CASE_FROM_PDF";
export const OPEN_APPLICATION_SETTINGS = "OPEN_APPLICATION_SETTINGS";
export const FILTER_ACCESSION_GRID = "FILTER-ACCESSION-GRID";
export const LOCK_EDITORS = "LOCK_EDITORS";
export const UNLOCK_EDITORS = "UNLOCK_EDITORS";
export const SCAN_IN_CASE = "SCAN_IN_CASE";
export const POPUP_SHOW = "POPUP-SHOW";
export const POPUP_HIDE = "POPUP-HIDE";
export const POPUP_ACTION = "POPUP-ACTION";
export const LOAD_SAVED_SEARCH = "LOAD_SAVED_SEARCH";
export const SAVE_SEARCH = "SAVE_SEARCH";
export const EDIT_SAVED_SEARCH_COLUMNS = "EDIT_SAVED_SEARCH_COLUMNS";
export const ENTER_ORDER_DEMO = "ENTER_ORDER_DEMO";
export const SPELL_CHECK_ACTION = "SPELL-CHECK-ACTION";
export const TOGGLE_PROOF_READ_DIALOG = "TOGGLE_PROOF_READ_DIALOG";
export const START_SPELLCHECK = "START_SPELLCHECK";
export const SIGN_CASE_ON_GRID = "SIGN_CASE_ON_GRID";
export const LAST_SPECIMEN_SAVED = "LAST_SPECIMEN_SAVED";
export const ICD_ENGINE_START = "ICD_ENGINE_START";
export const ICD_ENGINE_FINISH = "ICD_ENGINE_FINISH";
export const MACRO_START = "MACRO_START";
export const USED_RESULTS_MACRO = "USED_RESULTS_MACRO";
export const PRESSED_SIGNOUT_KEY = "PRESSED_SIGNOUT_KEY";
export const SIGN_CASE_FROM_SHORTKEY = "SIGN_CASE_FROM_SHORTKEY";
export const OPEN_STAGING_POPUP = "OPEN_STAGING_POPUP";
export const OPEN_SLIDE_IMAGES = "OPEN_SLIDE_IMAGES";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const SCANNED_IMAGE = "SCANNED_IMAGE";
export const SCANNED_ALL_IMAGE = "SCANNED_ALL_IMAGE";
export const TWAIN_TRANSFER = "TWAIN_TRANSFER";
export const TOGGLE_HIDE_PATH_REPORT = "TOGGLE_HIDE_PATH_REPORT";
export const REFRESH_EDITORS = "REFRESH_EDITORS";
export const TWAIN_OPERATION_STARTS = "TWAIN_OPERATION_STARTS";
export const TWAIN_OPERATION_ENDS = "TWAIN_OPERATION_ENDS";
export const OPEN_GENERAL_MACRO_POPUP = "OPEN_GENERAL_MACRO_POPUP";
export const RESTORE_GENERAL_EDITOR_POSITION = "RESTORE_GENERAL_EDITOR_POSITION";
export const UPDATE_TASK_COUNT = "UPDATE_TASK_COUNT";
export const SPELL_CHECK_STATUS = "SPELL_CHECK_STATUS";
export const UPDATE_CASE_DEMOGRAPHICS = "UPDATE_CASE_DEMOGRAPHICS";
export const OPEN_PROSTATE_POPUP = "OPEN_PROSTATE_POPUP";
export const UPDATE_CURRENT_SPECIMEN_PROSTATE = "UPDATE_CURRENT_SPECIMEN_PROSTATE";
export const SAVE_FROM_CASE_HEADER = "SAVE_FROM_CASE_HEADER";
export const UPDATE_CUSTOM_RESULTS_STATUS = "UPDATE_CUSTOM_RESULTS_STATUS";
export const OPEN_UNGROSSED_SPECIMEN = "OPEN_UNGROSSED_SPECIMEN";
export const SIGN_FROM_WINDOWED_PDF = "SIGN_FROM_WINDOWED_PDF";

// ** OBSERVABLES
export const ICDEngine$ = fromEventPattern(
  handler => eventBus.$on(RUN_ICD_ENGINE, handler),
  handler => eventBus.$off(RUN_ICD_ENGINE, handler)
);

export function fromBusEvent(event) {
  return fromEventPattern(
    handler => eventBus.$on(event, handler),
    handler => eventBus.$off(event, handler)
  );
}

// export const icdRulesEngine$ = ;

export default eventBus;
