<template>
  <div class="form-group">
    <label v-show="label" :for="$attrs.name || $attrs.id" :id="$attrs.id && $attrs.id + 'label'"
      ><b>
        {{ label }}
      </b>
    </label>
    <textarea
      class="form-control"
      :class="{
        'is-invalid':
          (validator && validator.$model && validator.$invalid) || (validator && validator.$error),
        'is-valid': validator && validator.$model && !validator.$invalid,
        'has-error': validator && validator.$model && validator && validator.$error
      }"
      ref="textArea"
      :value="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      :style="{ resize: resize ? 'vertical' : 'none', height: startHeight, width: width }"
    >
    </textarea>
    <div v-if="validator && validator.$invalid">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
    <Modal class="macro-popup" :status="isMacroOpen" @close="closeMacro">
      <macro-popup :targetType="4" @macroSelected="applyGeneralMacro" />
    </Modal>
  </div>
</template>

<script>
import { getTextFromHtml, useGeneralMacro, validatorMsgMapBase } from "@/modules/helpers";
import { mapState } from "vuex";
import Modal from "@/components/common/Modal";
import MacroPopup from "@/components/MacroPopup.vue";

export default {
  name: "TextAreaInput",
  inheritAttrs: false,
  props: {
    label: {
      type: String
    },
    resize: {
      default() {
        return true;
      }
    },
    value: {
      type: String
    },
    validator: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    startHeight: {
      default: "auto"
    },
    generalMacrosEnabled: {
      default: false
    },
    caseId: {
      default: null
    },
    width: {
      default: "100%"
    }
  },
  components: { MacroPopup, Modal },
  data() {
    return {
      isMacroOpen: false,
      positionToPlaceCursor: 0
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      currentUser: state => state.currentUser,
      generalMacros: state => state.generalMacros
    })
  },
  mounted() {
    this.loadGeneralMacros();
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  watch: {
    value: {
      handler(nv) {
        if (nv && this.generalMacrosEnabled) {
          this.handleTextChange(nv);
        }
      }
    }
  },
  methods: {
    focus() {
      this.$refs.textArea.focus();
    },
    setCursorLocation(index) {
      this.$refs.textArea.setSelectionRange(index, index);
    },
    loadGeneralMacros() {
      if (this.generalMacrosEnabled) {
        if (!this.generalMacros?.length) {
          this.$store.dispatch("getUserGeneralMacros");
        }
        if (!Object.keys(this.caseDetails).length && this.caseId) {
          this.loadCaseFromProps();
        }
      }
    },
    handleTextChange(input) {
      if (/\.\. /.test(input)) {
        this.isMacroOpen = true;
        this.positionToPlaceCursor = input.indexOf(".. ");
        return;
      }
      this.$emit("input", useGeneralMacro(input, this.generalMacros));
    },
    closeMacro() {
      this.isMacroOpen = false;
      this.$emit("input", this.value.replace(".. ", ""));
      this.$nextTick(() => {
        this.focus();
        this.setCursorLocation(this.positionToPlaceCursor);
      });
    },
    applyGeneralMacro(data) {
      this.isMacroOpen = false;
      let macroText = "";
      if (data.length) {
        macroText = getTextFromHtml(data[0].generalText) + " ";
        this.$emit("input", this.value.replace(".. ", macroText));
      } else {
        this.$emit("input", this.value.replace(".. ", ""));
      }
      this.$nextTick(() => {
        const newCursorIndex = this.positionToPlaceCursor + macroText.length;
        this.focus();
        this.setCursorLocation(newCursorIndex);
      });
    },
    loadCaseFromProps() {
      this.$store.dispatch("accessionStore/getCaseDetails", this.caseId);
    },
    handleKeydown(event) {
      const { code, altKey } = event;
      if (code === "KeyJ" && altKey) {
        const editor = this.$refs.textArea;
        if (editor === document.activeElement && this.value.includes("~")) {
          const startIndex = editor?.selectionEnd || 0;
          let indexOfTilde = this.value.indexOf("~", startIndex);
          if (indexOfTilde < 0) {
            indexOfTilde = this.value.indexOf("~");
          }
          editor.setSelectionRange(indexOfTilde, indexOfTilde + 1);
        }
      }
    }
  }
};
</script>

<style scoped></style>
