<template>
  <div v-if="open">
    <slot />
  </div>
</template>

<script>
import { scrollToElement } from "@/modules/helpers";
import { mapState } from "vuex";
import eventBus, { SIGN_FROM_WINDOWED_PDF } from "@/modules/eventBus";

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets)
    .filter(styleSheet => !styleSheet.href || styleSheet.href.startsWith(window.location.origin))
    .forEach(styleSheet => {
      if (styleSheet?.cssRules) {
        // for <style> elements
        const newStyleEl = sourceDoc.createElement("style");

        Array.from(styleSheet.cssRules).forEach(cssRule => {
          // write the text of each rule into the body of the style element
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });

        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) {
        // for <link> elements loading CSS from a URL
        const newLinkEl = sourceDoc.createElement("link");

        newLinkEl.rel = "stylesheet";
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    });
}
// import Vue from "vue";
export default {
  props: ["open", "name", "isReadyForSigning"],
  name: "window-portal",
  data() {
    return {
      windowRef: null,
      currentImageIndex: 0
    };
  },
  watch: {
    open(nv) {
      if (nv) {
        this.openPortal();
      } else {
        this.closePortal();
      }
    },
    windowRef(nv) {
      if (nv) {
        this.$emit("loaded", nv);
      }
    }
  },
  computed: {
    ...mapState({
      urls: state => state.sessionDetails.imageFrame.urls,
      signWithHotkeyOnPathReport: state => state.applicationSettings.signWithHotkeyOnPathReport
    })
  },
  methods: {
    async openPortal() {
      this.windowRef = window.open("", this.name || "Ip Pro Previewer", [
        "toolbar=no,status=no,location=no,scrollbars=no"
      ]);
      try {
        this.windowRef.focus();
        this.renderPortal();
      } catch (e) {
        window.alert("Popups are blocked, please allow them and try again.");
      }
    },
    renderPortal() {
      this.currentImageIndex = 0;
      this.windowRef.document.write("<head><title>IntelliPath Pro</title></head><body></body>");
      if (this.windowRef.document.body?.children?.length === 2) {
        this.windowRef.close();
        this.openPortal();
        return;
      }
      this.windowRef.document.body.appendChild(this.$el);
      copyStyles(window.document, this.windowRef.document);
      this.windowRef.name = this.name;
      this.windowRef.addEventListener("beforeunload", this.closePortal);
      this.windowRef.addEventListener("keydown", this.handleKeydown);
      this.windowRef.setInterval(() => this.handleBlur(), 1000);
    },
    closePortal() {
      if (this.windowRef) {
        this.windowRef.removeEventListener("keydown", this.handleKeydown);
        this.windowRef.close();
        this.windowRef = null;
        this.currentImageIndex = 0;
        this.$emit("close");
      }
    },
    handleKeydown(event) {
      function getElementAndScroll(index, windowRef) {
        const el = windowRef?.document?.getElementById("img" + index);
        scrollToElement(el);
      }
      const { key, altKey } = event;
      if (this.isReadyForSigning && altKey && ["x", "f12"].includes(key.toLowerCase())) {
        eventBus.$emit(SIGN_FROM_WINDOWED_PDF);
      }
      if (key === "Escape") {
        this.windowRef.close();
      }
      if (["ArrowLeft", "ArrowRight"].includes(key)) {
        event.preventDefault();
        if (key === "ArrowRight") {
          if (this.currentImageIndex < this.urls?.length) {
            this.currentImageIndex++;
          }
        }
        if (key === "ArrowLeft") {
          if (this.currentImageIndex > 0) {
            this.currentImageIndex--;
          }
        }
        getElementAndScroll(this.currentImageIndex, this.windowRef);
      }
    },
    handleBlur() {
      if (this.signWithHotkeyOnPathReport) {
        const activeElement = this.windowRef.document.activeElement;
        if (activeElement?.tagName === "OBJECT") {
          activeElement.blur();
        }
      }
    }
  },
  mounted() {
    if (this.open) {
      this.openPortal();
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal();
    }
  }
};
</script>

<style lang="scss" scoped></style>
