<template>
  <WindowPortal
    :open="isOpen"
    name="Intellipath viewer"
    class="main"
    @loaded="windowRef = $event"
    @close="handleClose"
    :isReadyForSigning="isReadyForSigning"
  >
    <path-report-viewer
      class="m-auto"
      @loaded="changeRoot"
      :windowRef="windowRef"
      @close="handleClose"
      @setReadyForSigning="setReadyForSigning"
    ></path-report-viewer>
  </WindowPortal>
</template>

<script>
import WindowPortal from "../WindowPortal";
import { mapGetters, mapState } from "vuex";
import PathReportViewer from "./PathReportViewer.vue";
export default {
  name: "ReportWrapper",
  props: {
    caseDetails: {
      default() {
        return {};
      }
    },
    reportName: {
      default() {
        return "UCSF-SurgTemplate2";
      }
    }
  },
  components: {
    PathReportViewer,
    WindowPortal
  },
  data() {
    return {
      reportPdf: "",
      reportHTML: "",
      dynamicRoot: "ROOTDIV",
      windowRef: null,
      isReadyForSigning: false
    };
  },
  computed: {
    ...mapState({
      reportViewer: state => state.report,
      applicationSettings: state => state.applicationSettings,
      isReportOpen: state => state.report.isOpen
    }),
    ...mapGetters("report", ["isWindowed"]),
    isOpen() {
      return this.isWindowed && this.isReportOpen;
    }
  },
  methods: {
    handleClose() {
      this.$store.commit("report/toggleReportViewer", false);
      this.$emit("close");
    },
    handleNewWindow() {
      this.$emit("windowed");
      this.$store.commit("report/toggleReportViewer", true);
    },
    changeRoot() {
      if (this.isOpen) {
        return (this.dynamicRoot = "WindowPortal");
      }
      return (this.dynamicRoot = "ROOTDIV");
    },
    setReadyForSigning(value) {
      this.isReadyForSigning = value;
    }
  }
};
</script>

<style lang="scss" scoped></style>
